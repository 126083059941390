import { createApp, configureCompat } from 'vue'
import createStore from './vuex/store'
import { router } from './router'
import { Chart } from 'chart.js'
import 'bootstrap'
import 'timesheet.js/dist/timesheet.min.js'
import toastr from 'toastr'
import { createBootstrap } from 'bootstrap-vue-next'

import InnerDonutChartPlugin from './plugins/InnerDonutChartPlugin'
import VueHighlightJS from './directives/vue-highlightjs'
import 'chartjs-plugin-datalabels'
import './chartjsJitter'
import DecodeRecursive from './plugins/DecodeRecursive'
import VueObserveVisibility from 'vue3-observe-visibility'
import vVisible from './directives/v-visible'
import uuidmixin from './plugins/uuidmixin'

import Risk3sixty from './components/Risk3sixty'
import mountReusables from './components/reusable'

configureCompat({
  MODE: 3,
  // Description of features: https://v3-migration.vuejs.org/migration-build.html

  // UNRESOLVED, FULLY FUNCTIONAL IN COMPAT MODE
  INSTANCE_SET: true, // caused by this.$set
  GLOBAL_EXTEND: true, // not sure what's causing this
  GLOBAL_MOUNT: true, // not sure what's causing this
  GLOBAL_SET: true, // not sure what's causing this

  // UNRESOLVED, FULLY FUNCTIONAL IN COMPAT MODE, CAUSED BY DEPENDENCIES
  INSTANCE_LISTENERS: true, // caused by bootstrap-vue-next
  CUSTOM_DIR: true, // caused by vuedraggable
  INSTANCE_EVENT_EMITTER: true, // caused by our use of bus.$on and bus.$off in various places
  TRANSITION_GROUP_ROOT: true, // caused by vuedraggable

  // SHOULD BE RESOLVED WITH BOOSTRAP-VUE-NEXT, BUT SEPERATING OUT IN CASE WE NEED TO FLIP THEM BACK
  OPTIONS_DATA_MERGE: false,
  OPTIONS_BEFORE_DESTROY: false,
  INSTANCE_SCOPED_SLOTS: false,
  COMPONENT_FUNCTIONAL: false,
  INSTANCE_ATTRS_CLASS_STYLE: false,
  INSTANCE_DESTROY: false,
  RENDER_FUNCTION: false,

  // FULLY RESOLVED
  ATTR_FALSE_VALUE: false, // will give warning if true but usage is intentional
  WATCH_ARRAY: false,
  GLOBAL_PROTOTYPE: false,
  OPTIONS_DESTROYED: false,
  COMPONENT_V_MODEL: false,
  GLOBAL_DELETE: false,
  GLOBAL_OBSERVABLE: false,
  CONFIG_KEY_CODES: false,
  CONFIG_WHITESPACE: false,
  INSTANCE_DELETE: false,
  INSTANCE_EVENT_HOOKS: false,
  INSTANCE_CHILDREN: false,
  OPTIONS_DATA_FN: false,
  V_ON_KEYCODE_MODIFIER: false,
  ATTR_ENUMERATED_COERCION: false,
  COMPONENT_ASYNC: false,
  FILTERS: false,
  CONFIG_IGNORED_ELEMENTS: false,
  PROPS_DEFAULT_THIS: false,
  GLOBAL_PRIVATE_UTIL: false,
  CONFIG_PRODUCTION_TIP: false,
  CONFIG_SILENT: false,
  TRANSITION_CLASSES: false,
})

const app = createApp(Risk3sixty)

const store = createStore(router)
app.use(store)
app.use(router)
app.use(
  createBootstrap({
    components: {
      BTooltip: {
        click: false,
      },
      BPopover: {
        click: true,
      },
    },
  })
)
mountReusables(app)

app.config.performance = !!window.webpackHotUpdate

Chart.register(InnerDonutChartPlugin)
Chart.defaults.set('plugins.datalabels', {
  ...Chart.defaults.plugins.datalabels,
  color: 'rgba(0, 0, 0, 0)',
})

import R3sFetch from './factories/R3sFetch'
import { handleFetchResponse } from './factories/ApiHelpers'

// css
import 'timesheet.js/dist/timesheet-white.min.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import './css/app.scss'
import './css/theme/extras.1.1.0.min.css'
import 'highlight.js/styles/default.css'
import 'highlight.js/styles/agate.css'
import 'vue-select/dist/vue-select.css'

app.mixin(uuidmixin)
app.use(DecodeRecursive)
app.use(VueObserveVisibility)
app.use(vVisible)
app.use(VueHighlightJS)

// Needed to make component `TypeaheadInput` work, see src at
// src/components/reusable/typeahead/typeahead.js
app.config.globalProperties.$http = {
  async get(src, { params }) {
    const queryStringDelim = src.includes('?') ? '&' : '?'
    if (params && params.search)
      src = `${src}${queryStringDelim}search=${params.search}`

    const response = await R3sFetch(src)
    const data = await handleFetchResponse(response)
    return { data }
  },
}

window.vueRouter = router
window.toastr = toastr
window.toastr.options.positionClass = 'toast-bottom-right toastr-bottom-margin'
window.toastr.options.escapeHtml = true // avoid xss

app.mount('#risk3sixty')

export default app
